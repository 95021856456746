<template>
<div id="features_container">
  <Heading  heading="Pucoreads Features"/>
  <BaseInfoComponent v-for="(feature, index) in features" :key="index"
                     :content="feature.content"
                     :title="feature.title" :img="feature.img" :reverse="index % 2 !== 0"
                     :is-show-content="true"
                     :left-action="'leftAction' in feature? feature.leftAction : null"
  />
</div>
</template>

<script>
import Heading from "@/components/atom/Heading";
import BaseInfoComponent from "@/components/molecules/BaseInfoComponent";
import featuresData from "@/components/MainApp/home/Features/featuresData";
export default {
  name: "FeaturesPucoreads",
  metaInfo: {
    meta: [
      {
        name: 'description',
        content: 'We present you our new upcoming service - Pucoreads. All book lovers and readers have a good news as Pucoreads is there to let you sell, exchange and buy books near you. Know its features and join the community.'
      },
      {
        name: 'author',
        content: 'Pucosa'
      },
      {
        name: 'keywords',
        content: 'pucoreads, pucosa, books, reading, sell book, used books, upcoming service, upcoming, sell, buy, exchange, buy book, exchange book, book, book lover, websites for books, book app, book apps, features, feature'
      }
    ]
  },
  components: {Heading, BaseInfoComponent},
  data: () => ({
    features: featuresData
  })
}
</script>

<style scoped lang="scss">
#features_container {
  margin-bottom: 2em;
}
</style>